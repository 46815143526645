import { firebase, hooks } from '@vette/frontend-utils';
import { Button } from '@vette/ui-components';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { EnterApplicationManuallyModal } from './EnterApplicationManuallyModal';

export const Header: React.FC = () => {
  const [handleReattachListeners, { loading: reattachListenersLoading }] =
    hooks.useLoadingPromiseHandler(() => firebase.database.remove('/listener'));
  const [showAddApplicationModal, setShowAddApplicationModal] = useState(false);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash.includes('#modal')) {
      setShowAddApplicationModal(true);
      const newUrl = window.location.href.replace('#modal', '');
      window.history.replaceState(null, '', newUrl);
    }
  }, []);

  return (
    <S.HeaderWrap>
      <Tooltip
        trigger={['hover']}
        title="Use this button if you see a session being stuck"
      >
        <Button
          type="primary"
          loading={reattachListenersLoading}
          onClick={handleReattachListeners}
        >
          Reattach Listeners
        </Button>
      </Tooltip>
      <Tooltip
        trigger={['hover']}
        title="Use this button to enter applications that haven't been scraped."
      >
        <Button
          type="primary"
          loading={false}
          onClick={() => setShowAddApplicationModal(true)}
        >
          Enter Application Manually
        </Button>
      </Tooltip>
      <EnterApplicationManuallyModal
        onCancel={() => setShowAddApplicationModal(false)}
        visible={showAddApplicationModal}
      />
    </S.HeaderWrap>
  );
};
