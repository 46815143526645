import React from 'react';
import { admin } from '@vette/pages';
import { firebase } from '@vette/frontend-utils';

const Dispatching: React.FC = () => (
  <firebase.FirebaseClaims>
    {() => <admin.Dispatching />}
  </firebase.FirebaseClaims>
);

export default Dispatching;
