import { useCallback, useState } from 'react';

export const useLoadingPromiseHandler = <
  TResult,
  TCallback extends (...args: unknown[]) => Promise<TResult>
>(
  callback: TCallback,
  initiallyLoading = false
) => {
  const [loading, setLoading] = useState(initiallyLoading);

  const wrappedCallback = useCallback(
    async (...args: unknown[]) => {
      setLoading(true);
      try {
        await callback(args);
      } catch (e) {
        setLoading(false);
        throw e;
      }
      setLoading(false);
    },
    [callback, setLoading]
  );

  return [wrappedCallback, { loading }] as const;
};
