import { useWarning } from '@vette/ui-components';
import { useAdminTerminateSessionMutation } from '@vette/data-access';
import { message } from '@vette/frontend-utils';

import * as S from './styles';

type Props = {
  sessionId: string;
};

export const EndSession = ({ sessionId }: Props) => {
  const warning = useWarning();

  const [endSession, { loading: submitting }] =
    useAdminTerminateSessionMutation({
      variables: {
        sessionId,
      },
      onCompleted: ({ adminTerminateSession }) => {
        if (adminTerminateSession.__typename === 'NotFoundError') {
          return message.error('Session not found');
        }
      },
    });

  const handleClick = () => {
    return warning.openWarning({
      text: 'Are you sure you want to end the session?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => endSession(),
    });
  };

  return (
    <>
      <S.CallEndButton type="ghost" loading={submitting} onClick={handleClick}>
        <S.CallIcon />
        End Session
      </S.CallEndButton>
    </>
  );
};
