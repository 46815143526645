import React, { useState } from 'react';
import {
  SessionStatusFilterEnum,
  useLastSessionsQuery,
  useSessionChangedSubscription,
} from '@vette/data-access';
import { DashboardLayout, PageCard, Table, Text } from '@vette/ui-components';
import { Row, Space, Tabs } from 'antd';
import { columns } from './columns';
import { hooks, useCustomRouter } from '@vette/frontend-utils';
import * as S from './styles';
import { Header } from './Header';

export const Dispatching: React.FC = () => {
  const [status, setStatus] = useState<SessionStatusFilterEnum>(
    SessionStatusFilterEnum.IN_PROGRESS
  );
  const { data, loading, refetch } = useLastSessionsQuery({
    variables: { filter: status },
  });
  const { getPath, pages } = useCustomRouter();

  hooks.useThrottleSubscription(refetch, 5000, useSessionChangedSubscription);

  const { TabPane } = Tabs;

  const callback = (key: SessionStatusFilterEnum) => {
    setStatus(key);
  };

  const table = (sessionStatus: SessionStatusFilterEnum) => (
    <Table
      expandable={{
        expandedRowRender: record => {
          return (
            <>
              <Row justify="center">
                <S.PersonInformation
                  $vetterOrClientNotExist={!record.applicant}
                >
                  {record.applicant ? (
                    <>
                      <Row justify="center">
                        <Text>
                          {`Applicant: ${record.applicant.firstName} ${record.applicant.lastName}`}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text>Phone: {record.applicant.phone}</Text>
                      </Row>
                    </>
                  ) : (
                    'No Applicant Information'
                  )}
                </S.PersonInformation>
              </Row>
              <Row justify="center">
                <S.PersonInformation $vetterOrClientNotExist={!record.vetter}>
                  {record.vetter ? (
                    <>
                      <Row justify="center">
                        <Text>{`Vetter: ${
                          record.vetter.firstName
                        } ${record.vetter.lastName?.charAt(0)}.`}</Text>
                      </Row>
                      <Row justify="center">
                        <Text>Phone: {record.vetter.phone}</Text>
                      </Row>
                    </>
                  ) : (
                    'No Vetter Information'
                  )}
                </S.PersonInformation>
              </Row>
            </>
          );
        },
      }}
      dataSource={data?.lastSessions.map(session => {
        // we need to add key to avoid expanding all the rows
        return { ...session, key: session?.id };
      })}
      scroll={{ x: '100%' }}
      columns={columns(sessionStatus)}
      getRowLink={record =>
        record?.id
          ? getPath(
              pages.admin.clients_applicant_details({
                clientId: record.jobOffer?.client?.id,
                sessionId: record.id,
              })
            )
          : '#'
      }
    />
  );

  return (
    <DashboardLayout loading={loading}>
      <PageCard title="Dispatching">
        <Space className="dispatching" direction="vertical" size={12}>
          <Header />
          <Tabs
            centered
            defaultActiveKey={status}
            onChange={tab => callback(tab as SessionStatusFilterEnum)}
          >
            <TabPane
              tab="In Progress"
              key={SessionStatusFilterEnum.IN_PROGRESS}
            >
              {table(SessionStatusFilterEnum.IN_PROGRESS)}
            </TabPane>
            <TabPane tab="Completed" key={SessionStatusFilterEnum.COMPLETED}>
              {table(SessionStatusFilterEnum.COMPLETED)}
            </TabPane>
          </Tabs>
        </Space>
      </PageCard>
    </DashboardLayout>
  );
};
