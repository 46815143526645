import React, { useCallback } from 'react';
import { PhoneInputProps } from 'react-phone-input-2';
import { phone } from '@vette/common-utils';

import * as S from './styles';

export { default as phoneInputValidations } from './validations';

type Props = {} & PhoneInputProps;

export const PhoneInput: React.FC<Props> = ({ onChange, ...props }) => {
  const handleOnChange = useCallback(
    (
      value: string,
      data: {},
      event: React.ChangeEvent<HTMLInputElement>,
      formattedValue: string
    ) => {
      return onChange?.(phone.normalize(value), data, event, formattedValue);
    },
    [onChange]
  );

  return (
    <S.Wrap>
      <S.Input
        inputClass="ant-input"
        specialLabel=""
        disableDropdown
        onChange={handleOnChange}
        {...props}
      />
    </S.Wrap>
  );
};
