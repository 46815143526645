import { Row } from 'antd';
import { Button as BaseButton } from '@vette/ui-components';
import styled from 'styled-components';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { SimpleModal } from '@vette/ui-components';

export const Modal = styled(SimpleModal)``;

export const PersonInformation = styled(Row)<{
  $vetterOrClientNotExist?: boolean;
}>`
  background: ${({ $vetterOrClientNotExist, theme }) =>
    !$vetterOrClientNotExist ? theme.colors.green20 : theme.colors.red20};
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrap = styled.div`
  display: flex;
  gap: 4px;
`;

export const CallEndButton = styled(BaseButton)`
  border: none;
  background-color: transparent;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  display: flex;
  min-width: 50px;
  white-space: nowrap;
  padding: 0;
`;

export const CallIcon = styled(CallEndIcon)`
  padding-right: 6.11px;
`;
