import styled from 'styled-components';
import PhoneNumberInput from 'react-phone-input-2';

export const Wrap = styled.div`
  & .ant-input {
    padding: 13px 12px;
    border-radius: 12px;
    font-size: 15px;
    line-height: 140%;
  }

  & .ant-input:focus,
  & .ant-input:hover {
    border-color: ${({ theme }) => theme.colors.black30};
    box-shadow: none;
  }
`;

export const Input = styled(PhoneNumberInput)``;
